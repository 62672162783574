import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  ProgressBar,
  Spinner,
  Table,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import { ReactMic } from "react-mic";
import { Icon, QuillMinimal } from "../../../../components";
import { convertTimeToMinutesAndSeconds } from "../../../../components/FormatDate/formatDate";
import NoUiSlider from "../../../../components/Form/NoUiSlider";
import Footer from "../../../../layout/default/Footer";
import Tinymce from "../../../../components/Form/editors/Tinymce";
import { KanbanBasic } from "../../../../components/Kanban/Kanban";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import AdminApi from "../../../../api/admin";
import ReadAloud from "../SpeakingTest/ReadAloud";
import AnswerShortQuestions from "../SpeakingTest/AnswerShortQuestions";
import RepeatSenetence from "../SpeakingTest/RepeatSentence";
import DescribeImage from "../SpeakingTest/DescribeImage";
import ReTellLectureImage from "../SpeakingTest/ReTellLectureImage";
import SummarizeWrittenText from "../WritingTest/SummarizeWrittenText";
import WriteEssay from "../WritingTest/WriteEssay";
import ReadWriteFillinBlanks from "../ReadingTest/ReadWriteFillinBlanks";
import ReadingMultichoice from "../ReadingTest/ReadingMultichoice";
import ReadingReOrder from "../ReadingTest/ReadingReOrder";
import ReadingFillInBlanks from "../ReadingTest/ReadingFillInBlanks";
import ListeningSummarizeSpokenText from "../ListeningTest/SummarizeSpokenText";
import ListeningMultiChoiceMulti from "../ListeningTest/MultiChoiceMulti";
import ListeningMultiChoiceSingle from "../ListeningTest/MultiChoiceSingle";
import ListeningFillInBlanks from "../ListeningTest/FillInBlanks";
import ListeningHighlightCorrectSummary from "../ListeningTest/HighlightCorrectSummary";
import ListeningHighlightIncorrectWord from "../ListeningTest/HighlightIncorrectWord";
import ListeningDictation from "../ListeningTest/Dictation";
import SelectMissingWord from "../ListeningTest/SelectMissingWord";
import ReadingMultichoiceSingle from "../ReadingTest/ReadingMultichoiceSingle";
import Timer from "../Timer";
import { add_answer_sheet } from "./submitAnswer";
import Context from "../../../../context";
import { useContext } from "react";
import ModalContext from "../../../../context/ModalContext";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { start } from "@popperjs/core";
import ProgressBarCustom from "../CustomProgressbar";
import { S3upload } from "../S3upload";
import ListeningInstruction from "./ListeningInstruction";
import InstructionSpeaking from "./InstrucSpeaking";
import InstructionReading from "./InstrucReading";
import InstructionTable from "./InstructionTable/InstructionTable";
import TimeRemaining from "../TimeRemaining/Timer";
import SpeedTestForMockTest from "./SpeedTestForMockTest";
function PTEScoredTestIntro() {
  let { userData, setUserData, localHost, incorreCtDomain,domainData, userHilingotoken } =
    useContext(Context);

  userData = JSON.parse(userData);
  domainData = JSON.parse(domainData);

  useEffect(() => {
    
    document.documentElement.style.setProperty('--theme-color',domainData?.inst_profile?.color_code);
 

},[])

  let {
    studentContextData,
    setStudentContextData,
    popUpType,
    setPopUpType,
    setAnswerJson,
    answerJson,
    manageRecordings,
    setManageRecordings,
  } = useContext(ModalContext);
  var { timeRamaining ,current_time} = studentContextData
  var ans;

  let { id, param, _id } = useParams();
  
  console.log("ELEMENTS",id,param,_id);
  let all = "all";
  let section_wise = "sectionType";
  let examtype = localStorage.getItem("examType");
  console.log("examtype",examtype);
  let navigate = useNavigate();
  const [question, setQuestion] = useState([]);
  console.log("QUESTIONS",question);
  const [destructure, setDestructure] = useState(0);
  const [count_module_wise, setQuestionCount_module_wise] = useState(1);
  const [forMicSpeakerUI, setforMicSpeakerUI] = useState({
    testmic: 0,
    testspeaker: 0,
    internetspeed: 0,
  });
  const [examsTime, setExamsTime] = useState({});
  const [paperId, setPaperId] = useState("");
  var { testmic, testspeaker, internetspeed } = forMicSpeakerUI;
  const [internetSpeedTest, setInternetSpeedTest] = useState(false)
  const [speakerWorking, setspeakerWorking] = useState(true);
  let ques = question[destructure];
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    

    //to check online
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
    
  }, []);

  useEffect(() => {
    //to check microphone
    // Set up interval to check periodically
    let intervalId;
    intervalId = setInterval(checkMicrophoneAvailability, 200); 

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  },[])

  console.log("isOnline", isOnline)
  // console.log("question",question)
  // console.log("destructure",destructure)
  const { startRecording, stopRecording, recordingBlob, isRecording } =
    useAudioRecorder();

  useEffect(() => {
    if (manageRecordings.start) {
      startRecording();
      setAnswerJson({ ...answerJson, audio_url: "" });
    }
    if (manageRecordings.stop) {
      stopRecording();
    }
  }, [manageRecordings]);

  const [fullscreen, setFullscreen] = useState(false);
  const [examId, setExamId] = useState(null);
  const [NoteModal, setNoteModal] = useState(false);
  const NoteModalClose = () => setNoteModal(false);
  const NoteModalShow = () => setNoteModal(true);

  const [BeginExamModal, setBeginExamModal] = useState(false);
  const BeginExamModalClose = () => setBeginExamModal(false);
  const BeginExamModalShow = () =>
    setBeginExamModal(true) || setNoteModal(false);

  const [NextQuestionModal, setNextQuestionModal] = useState(false);
  const NextQuestionModalClose = () => setNextQuestionModal(false);
  const NextQuestionModalShow = () => setNextQuestionModal(true);
console.log('NextQuestionModal',NextQuestionModal);
  const [EndExamModal, setEndExamModal] = useState(false);
  const EndExamModalClose = () => {
    setEndExamModal(false);
    navigate("/student/mock/list");
  };
  const EndExamModalShow = () => {
    setEndExamModal(true);
  };
  const [seconds, setSeconds] = useState(0);
  const [countStep, setCountStep] = useState(1);
  const [instCount, setInstCount] = useState(0);
  const [viewInstruction, setViewInstruction] = useState(null);
  const [enableSaveAndExistAlert, setEnableSaveAndExistAlert] = useState(false);
  const [record, setRecord] = useState(true);
  const [progress, setProgress] = useState(0);
  const [current_qst, setcurrentQues] = useState([])
  const onData = (recordedBlob) => {
    const newProgress = Math.min((recordedBlob.size / (100 * 1000)) * 100, 100); // Limit it to 100
    setProgress(newProgress);
  };
  const onStop = (recordedBlob) => {
    setProgress(0);
  };
  console.log("COUNT_STEP",countStep);
  useEffect(() => {
    if (param == "exam") {
      console.log("USEEFFECT");
      get_pending_paper();
    }
    if (param == "ans") {
      console.log("USEEFFECT");
      restart_paper();
    }
    if (examtype == "questionType") {
      console.log("USEEFFECT");
      get_paper();
      setCountStep(null)
    }

  }, [param, _id]);

  const get_pending_paper = async () => {
    setLoader(true);
    await api.get_pending_paper(_id,userHilingotoken).then((res) => {
      if (res?.status) {
        console.log("resresresresresres",res)
        setLoader(false);
        // setInstCount(null);
        setCountStep(null);
        setViewInstruction(null);
        setQuestion(res.data[0].questID);
        setcurrentQues(res.module_wise_count)
        setAnswerJson({ ...answerJson, exam_id: res.data[0]._id, id: res?.id });
      }
    });
  };
  const restart_paper = async () => {
    setLoader(true);
    await api.restart_paper(_id).then((res) => {
      if (res.status) {
        // console.log("res",res)
        setLoader(false);
        // setQuestion(res.data[0].questID.reverse());
        get_paper();
        setCountStep(null)
      }
    });
  };

  useEffect(() => {
    if (param != "exam" && param != "ans") {
      setAnswerJson({
        ...answerJson,
        exam_type: ques?.exam_type,
        id: "",
        inst_id: userData?.stu_profile?.inst_id
          ? userData?.stu_profile?.inst_id
          : "64bfcc9c72e4a6a0f4e706a4",
        student_id:
          userData.login_type == "2"
            ? userData._id
            : "64ca27bfca54bd4d24ba7707",
        qst_id: ques?._id,
        answer: ans,
        status: false,
      });
    }
    getExamTime();
    if (countStep == 1) {
      setStudentContextData((prevState) => ({
        ...prevState,
        timeRamaining: {
          ...prevState.timeRamaining,
          status: false,
          total_time: '00:00',
        },
      }))
    }
  }, []);
  const getExamTime = async () => {
    setLoader(true);
    function addTime(first, second) {
      const firstTime = first.split(':').map(Number);
      const secondTime = second.split(':').map(Number);
      const totalSeconds = (firstTime[0] + secondTime[0]) * 3600 + (firstTime[1] + secondTime[1]) * 60 + (firstTime[2] + secondTime[2]);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      const result = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      return result;
    }

    await api.get_paper(id).then((res) => {
 
      if (res?.status) {
        if(res?.data?.length){
          setPaperId(res?.data[0]?._id)
        }
        setPopUpType("default");
        setLoader(false);
        let {
          listening_time,
          speaking_time,
          reading_time,
          total_time,
          writing_time,
        } = res?.data[0];
        setExamsTime({
          listening_time,
          speaking_time,
          reading_time,
          total_time,
          writing_time,
          speaking_and_Writing_time: addTime(speaking_time, writing_time)
        });
      }
    });
  };

  const nextStep = (timeUp) => {
 
    if (window.location.host.includes("localhost")) {
      setPopUpType("default")
    }
    // if(examtype == "sectionType"){
    //   setquestionStart(questionStart+1)
    // }

    console.log("viewInstruction", viewInstruction)
    if (param != "exam" && param != "ans") {
      if (viewInstruction == "speaking" && !timeUp) {
        if (instCount > 2) {
          setViewInstruction(null);
          setInstCount(0);
        } else {
          setInstCount((prev) => prev + 1);
        }

        return;
      }
      if (viewInstruction == "speaking" && timeUp) {
        setViewInstruction(null);
        setInstCount(0);
        return;
      }
      if (viewInstruction == "reading") {
        // if (instCount > 0) {

        //  setDestructure(destructure+1)
        setViewInstruction(null);
        setInstCount(0);
        // } else {
        //   setInstCount((prev) => prev + 1);
        // }

        return;
      }
      if (viewInstruction == "listening" && !timeUp) {
        if (instCount > 1) {
          setViewInstruction(null);
          setInstCount(0);
        } else {
          setInstCount((prev) => prev + 1);
        }

        return;
      }
      if (viewInstruction == "listening" && timeUp == true) {
        setViewInstruction(null);
        setInstCount(0);

        return;
      }
      if (countStep == 4) {
        setBeginExamModal(true)
      }
      if ((question.length && viewInstruction == null) || viewInstruction == "writing") {
        if (isRecording) {
          stopRecording();
        }
        NextQuestionModalShow();
      }
      if (countStep < 9) {
        setCountStep(examtype != "questionType" ? countStep + 1 : null);
      }
    }
    if (param == "exam" || param == "ans") {
      if (viewInstruction == "speaking" && !timeUp) {
        if (instCount > 2) {
          setViewInstruction(null);
          setInstCount(0);
        } else {
          setInstCount((prev) => prev + 1);
        }

        return;
      }
      if (viewInstruction == "speaking" && timeUp) {
        setViewInstruction(null);
        setInstCount(0);
        return;
      }
      if (viewInstruction == "reading") {
        // if (instCount > 0) {

        //  setDestructure(destructure+1)
        setViewInstruction(null);
        setInstCount(0);
        // } else {
        //   setInstCount((prev) => prev + 1);
        // }

        return;
      }
      if (viewInstruction == "listening" && !timeUp) {
        if (instCount > 1) {
          setViewInstruction(null);
          setInstCount(0);
        } else {
          setInstCount((prev) => prev + 1);
        }

        return;
      }
      if (viewInstruction == "listening" && timeUp == true) {
        setViewInstruction(null);
        setInstCount(0);

        return;
      }
      if (viewInstruction == null || viewInstruction == "writing") {
        NextQuestionModalShow();
      }
    }
    
  };
  const prevStep = () => {

    setCountStep(countStep - 1);
  };
  const saveExitStep = () => {
    setEnableSaveAndExistAlert(true);
    // setCountStep(12);
    // setCountStep(1);
  };
  let [loader, setLoader] = useState(false);

  let api = new AdminApi();
  const get_paper = async () => {
    setLoader(true);
    await api.get_paper(id).then((res) => {
      if (res.status) {
        console.log("GETPAPER");
        if(res?.data?.length){
          setPaperId(res?.data[0]?._id)
        }
        setLoader(false);
        setQuestion(res.data[0].questID);
        setAnswerJson({ ...answerJson, exam_id: res.data[0]._id });
      }
    });
  };

  const audioRef = useRef(null);
  const submitAnswer = async () => {
    if(examtype == "sectionType"){
      setquestionStart(questionStart+1)
    }
    
    var audio_url = answerJson.audio_url;
    let speaking_question = question[destructure]?.module === "speaking"
    if (recordingBlob && speaking_question) {
      console.log("recordingBlob");
      setManageRecordings({ ...manageRecordings, start: false, stop: false });
      await S3upload(
        recordingBlob,
        `answers/${answerJson?.exam_id}/${Date.now()}_${userData?._id
        }.ogg`
      )
        .then(async (url) => {
          console.log("URLCOME",url);
          audio_url = url;
          //  await setAnswerJson({ ...answerJson, audio_url: url });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }

    if (destructure + 1 < question.length) {
      (await question[destructure]?.module) !==
        question[destructure + 1]?.module &&
        examtype === all &&
        setViewInstruction(question[destructure + 1]?.module);
      if ((viewInstruction == null || viewInstruction === "writing") && question.length) {
        setDestructure(destructure + 1);
        setQuestionCount_module_wise(count_module_wise + 1)
      }
      if (!speaking_question) {
        audio_url = null;
      }

      
      console.log("IPHONE",audio_url);
      
      add_answer_sheet({ ...answerJson, audio_url: audio_url, module_name: ques?.module_name, question_content: ques?.question_content }, setAnswerJson, question[destructure]?.module_name, question[destructure]?.module, question[destructure]);
      setNextQuestionModal(false);
      setPopUpType("default");
      console.log("if");
    } else {
      console.log("else");

      add_answer_sheet({ ...answerJson,audio_url: audio_url, status: true }, setAnswerJson,question[destructure]?.module_name);
      setCountStep("endExam");
      setViewInstruction("endExam");
      setPopUpType("test");
    }
  };
  const setModal = (e) => {
    // console.log("eee", e);
  };
  useEffect(() => {
    if (
      question.length &&
      examtype == all &&
      param != "exam" &&
      param != "ans"
    ) {
      if (question[0].module == "speaking") {
        setViewInstruction("speaking");
      }
      if (question[0].module == "reading") {
        setViewInstruction("reading");
      }
      if (question[0].module == "listening") {
        setViewInstruction("listening");
      }
    }

  }, [question]);

  useEffect(() => {
    if (ques?.module == "writing") {
      setStudentContextData((prevState) => ({
        ...prevState,
        timeRamaining: {
          ...prevState.timeRamaining,
          status: true,
          total_time: question[destructure]?.answer_time,
        },
      }));
    };
    if (param != "exam" && param != "ans") {
      setAnswerJson({
        ...answerJson,
        id: "",
        inst_id: userData?.stu_profile?.inst_id
          ? userData?.stu_profile?.inst_id
          : "64bfcc9c72e4a6a0f4e706a4",
        student_id:
          userData.login_type == "2"
            ? userData._id
            : "64ca27bfca54bd4d24ba7707",
        qst_id: question[destructure]?._id,
        status: false,
        exam_type: question[destructure]?.exam_type,
      });
    } else {
      setAnswerJson({
        ...answerJson,
        inst_id: userData?.stu_profile?.inst_id
          ? userData?.stu_profile?.inst_id
          : "64bfcc9c72e4a6a0f4e706a4",
        student_id:
          userData.login_type == "2"
            ? userData._id
            : "64ca27bfca54bd4d24ba7707",
        qst_id: question[destructure]?._id,
        status: false,
        exam_type: question[destructure]?.exam_type,
      });
    }
  }, [question[destructure]]);

  function extractTime(inputStr) {
    const regex = /(\d+:\d+:\d+)/;
    const match = inputStr?.match(regex);

    if (match) {
      const timeStr = match[0];
      const [hours, minutes, seconds] = timeStr.split(":");

      if (hours && minutes && seconds) {
        const totalSeconds =
          parseInt(hours, 10) * 3600 +
          parseInt(minutes, 10) * 60 +
          parseInt(seconds, 10);

        if (totalSeconds < 60) {
          return `${totalSeconds} seconds`;
        } else if (totalSeconds < 3600) {
          const minutes = Math.floor(totalSeconds / 60);
          return `${minutes} minutes`;
        } else {
          const hours = Math.floor(totalSeconds / 3600);
          return `approx ${hours} hour`;
        }
      }
    }

    return "Invalid input";
  }
  let currentModuleName = ((viewInstruction == null) || ques?.module == "writing") ? ques?.module_name == "ssts"?ques?.module_name:ques?.module : "null";
  useEffect(() => {
    if (examtype == "questionType" || examtype == "sectionType") {
      if (instCount > 3) {
        console.log("examsTime",examsTime);
        setStudentContextData((prevState) => ({
          ...prevState,
          timeRamaining: {
            ...prevState.timeRamaining,
            status: true,
            total_time: examsTime?.total_time,
          },
        }));
      }
      return;
    }
    if (currentModuleName == "speaking") {
      setQuestionCount_module_wise(1)
      setStudentContextData((prevState) => ({
        ...prevState,
        timeRamaining: {
          ...prevState.timeRamaining,
          status: true,
          total_time: convertTimeToMinutesAndSeconds(examsTime?.speaking_time),
        },
      }));
      return
    };
    if (currentModuleName == "reading") {
      setQuestionCount_module_wise(1)
      setStudentContextData((prevState) => ({
        ...prevState,
        timeRamaining: {
          ...prevState.timeRamaining,
          status: convertTimeToMinutesAndSeconds(examsTime?.reading_time) != "0:00" ? true : false,
          total_time: convertTimeToMinutesAndSeconds(examsTime?.reading_time),
        },
      }));
      return
    };
    if(currentModuleName == "ssts"){
      setQuestionCount_module_wise(1)
      setStudentContextData((prevState) => ({
        ...prevState,
        timeRamaining: {
          ...prevState.timeRamaining,
          status: true,
          total_time: ques?.answer_time,
        },
      }));}
    if (currentModuleName == "listening") {
      setStudentContextData((prevState) => ({
        ...prevState,
        timeRamaining: {
          ...prevState.timeRamaining,
          status: true,
          total_time: convertTimeToMinutesAndSeconds(examsTime?.listening_time),
        },
      }));
      return
    };
    if (ques?.module == "writing") {
      setStudentContextData((prevState) => ({
        ...prevState,
        timeRamaining: {
          ...prevState.timeRamaining,
          status: true,
          total_time: ques?.answer_time,
        },
      }));
      return
    };

  }, [currentModuleName])
  const [count, seTcount] = useState(0)

  useEffect(() => {
    seTcount(count + 1)
  }, [ques])
  
const saveCurrentTime = async () =>{
  if(!answerJson.id){ return }
     await api.set_current_time({paperId:answerJson.id,current_Time:current_time}).then((res)=>{
         console.log("ress")
     }).catch((err)=>{
         console.log(err)
     })
}

  let questionNumberTotal = question?.length && (ques?.module == "speaking" || ques?.module == "writing") ? question.filter((item) => item.module == "speaking" || item.module == "writing").length :
    ques?.module == "listening" ? question.filter((item) => item.module == "listening").length :
      ques?.module == "reading" ? question.filter((item) => item.module == "reading").length :
        0
  const [isSpeaking,setIsSpeaking] = useState(false)
        const[questionStart,setquestionStart]  = useState(1)
let questionStart1 =  current_qst?.length>0 && ((ques?.module == "speaking" || ques?.module == "writing") ? (current_qst?.length>0 &&  (current_qst?.find((item)=>item._id=="speaking")?.count + current_qst?.find((item)=>item._id=="writing")?.count)-questionNumberTotal) + count_module_wise :
ques?.module == "listening" ? (current_qst.length>0 &&  (current_qst.find((item)=>item._id=="listening").count)-questionNumberTotal) + count_module_wise:
ques?.module == "reading" ? (current_qst.length>0 &&  (current_qst.find((item)=>item._id=="reading").count)-questionNumberTotal) + count_module_wise:
    1)
useEffect(()=>{
  setquestionStart(questionStart1)
  if(examtype == "sectionType"){
    setquestionStart(1)
  }
},[questionStart1])
let questionTotal =(ques?.module == "speaking" || ques?.module == "writing") ? current_qst?.length>0 &&  current_qst.find((item)=>item._id=="speaking")?.count + current_qst.find((item)=>item._id=="writing")?.count : ques?.module == "listening" ? current_qst.find((item)=>item._id=="listening")?.count : ques?.module == "reading" ? current_qst?.find((item)=>item._id=="reading")?.count :1;
  
  useEffect(() => {
    if(viewInstruction == "speaking"){
      setIsSpeaking(true)
    }
    else if(viewInstruction == "writing"){
      setIsSpeaking(false);
    }
    else if(viewInstruction == "listening"){
      setIsSpeaking(false);
    }
    else if(viewInstruction == "reading"){
      setIsSpeaking(false);
    }
  },[viewInstruction])

  // const checkMicrophoneAvailability = async () => {
  //   try {
  //     const devices = await navigator.mediaDevices.enumerateDevices();
  //     const hasMicrophone = devices.some(device => device.kind === 'audioinput');
  //     console.log("ISMICROPHONE",hasMicrophone);
  //     setRecord(hasMicrophone);
  //   } catch (error) {
  //     console.error('Error checking microphone availability:', error);
  //     setRecord(true);
  //   }
  // };

  const checkMicrophoneAvailability = async () => {
    try {
      // Request permission to access the microphone
      await navigator.mediaDevices.getUserMedia({ audio: true });

      // Enumerate devices to check for microphone presence
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasMicrophone = devices.some(device => device.kind === 'audioinput');
      console.log("Is Microphone Available:", hasMicrophone);
      setRecord(hasMicrophone);
    } catch (error) {
      console.error('Error checking microphone availability:', error);
      setRecord(false);
    }
  };

  console.log("VIEWINSTRUCTION",viewInstruction,count_module_wise, questionNumberTotal)
  console.log("questionNumberTotal",questionNumberTotal);
  console.log("isSpeaking",isSpeaking);
  console.log("question",question);
  console.log("QUESTIONSTART",questionStart,questionStart1);
  return (
    <div className="exam-screen">
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between bg-examtop align-items-center px-3 exam-topbar">
          <h3 className="text-examheadtext">
            PTE Academic - {userData?.name.toUpperCase()}
          </h3>
          <div className="d-flex flex-column">
            {examtype == "all" && <p className="d-flex flex-column text-examheadtext mb-0">
              {(timeRamaining?.status && (((viewInstruction == "speaking" && instCount == 3) ? popUpType != "recordingStopped" : viewInstruction == "speaking") || (viewInstruction == "reading" && instCount == 0) || (viewInstruction == "listening") || (viewInstruction == null || viewInstruction == "writing"))) && <small>
                <span>Time Remaining</span>  <Icon name="clock"></Icon> <TimeRemaining destructure={destructure} setDestructure={(value) => { setDestructure(value) }} question={question} currentModuleName={currentModuleName} timer={timeRamaining.total_time} />
              </small>}
            </p>}

            
            {(examtype == "sectionType" || examtype == "questionType") && 
  (ques?.module_name === "swts" || ques?.module_name === "essays") && <p className="d-flex flex-column text-examheadtext mb-0">
              {(timeRamaining?.status && (((viewInstruction == "speaking" && instCount == 3) ? popUpType != "recordingStopped" : viewInstruction == "speaking") || (viewInstruction == "reading" && instCount == 0) || (viewInstruction == "listening") || (viewInstruction == null || viewInstruction == "writing"))) && <small>
                <span>Time Remaining</span>  <Icon name="clock"></Icon> <TimeRemaining destructure={destructure} setDestructure={(value) => { setDestructure(value) }} question={question} currentModuleName={currentModuleName} timer={timeRamaining.total_time} />
              </small>}
            </p>}


            <p className="d-flex flex-column text-examheadtext mb-0">
              {(examtype=="sectionType" && (ques?.module_name != "swts" && ques?.module_name != "essays") ) ? countStep>=5 && countStep != "endExam" && viewInstruction != "endExam" && <small>
                <span>Time Remaining</span>  <Icon name="clock"></Icon> {question.length>0 && <TimeRemaining examType={examtype} timer={examsTime.total_time} />} 
              </small>:examtype=="questionType" && countStep != "endExam" && viewInstruction != "endExam" &&<small>
                <span>Time Remaining</span>  <Icon name="clock"></Icon> {question.length>0 && <TimeRemaining examType={examtype} timer={examsTime.total_time} />} 
              </small>}
            </p>

            {(viewInstruction == "writing") && question.length > 0 && (
              <p className="text-examheadtext">
                <small>
                  <Icon name="files"></Icon>{" "}
                  <span>
               

                    
                    {param =="exam"?questionStart    + " of " +  + questionTotal: (count_module_wise - question.filter((item) => item.module == "speaking").length ) + " of " + (questionNumberTotal - question.filter((item) => item.module == "speaking").length)}
                  </span>
                </small>
              </p>
            )}
          
            {examtype == "sectionType" ? countStep>5 && questionStart + " of " + question?.length:
            (viewInstruction == null) && question.length > 0 && (
              <p className="text-examheadtext">
                <small>
                  <Icon name="files"></Icon>{" "}
                  <span>
                    
                    {param =="exam"?questionStart    + " of " +  + questionTotal: count_module_wise  + " of " + (questionNumberTotal - (isSpeaking ? (question.filter((item) => item.module == "writing").length ) : 0))}
                    
                  </span>
                </small>
              </p>
            )}
            
          </div>
        </div>
        <p className="text-white bg-examblue p-3">
          {/* <Button variant="transparent" onClick={NoteModalShow}>
            <img src="/images/exam-note.svg"></img>
            <b className="text-white ms-1"> Notes</b>
          </Button> */}
        </p>
      </div>

      <div className="my-4 px-5">
        <div className="exam-card">
          {countStep == 33 && (
            <div class="pos">
              <iframe
                src="https://openspeedtest.com/Get-widget.php"
                width="735"
                scrolling="no"
                height="490"
                frameborder="0"
              ></iframe>
              <br />
              <div style={{ width: "725px" }}></div>
            </div>
          )}
          {countStep == 1 && (param != "ans") && (
            <>
              <>
                <p className="bg-examblue text-white px-3 py-1">
                  {/* <img
                    src="/images/favicon.png"
                    alt="logo"
                    className="intro-logo"
                  />{" "} */}
                  HiLingo | PTE Academic
                </p>
                <Row className="my-3">
                  <Col md="12" className="text-examheadtext">
                    <h4>Disclaimer</h4>
                    <p><strong>Hilingo.com </strong>preparation platform supports students in getting ready for Pearson's Test of English (PTE).</p>
                    <p>
                      {" "} It's important to note that this is not an official PTE Academic Exam but rather a mock test designed for PTE Academic Exam practice. The content is compiled from diverse sources, and Hilingo.com explicitly disclaims ownership and liability for its content. The expressed views belong to the author, not the company. Users are cautioned against making defamatory statements or violating copyright. The company disclaims responsibility for such actions, and individuals assume personal liability for any resulting damages. Unauthorized use or disclosure of the information is strictly prohibited.{" "}
                    </p>
                  </Col>
                </Row>
                <p className="bg-examblue text-white px-3 mt-5">
                  Click on the Next (N)
                  button to continue
                </p>
              </>
            </>
          )}
          {countStep == 2 && (
            <Row className="my-3">
              <Col md="12">
                <div class="d-flex justify-content-center align-items-center mb-3">
                  <img
                    src="/images/system-test.svg"
                    style={{ width: "70px" }}
                  ></img>
                  <span className="text-examheadtext ms-3">
                    System test: Diagnostics - Equipment checks{" "}
                  </span>
                </div>
              </Col>
              <Col md="12">
                <div className="bg-primary-soft p-5">
                  <Row className="my-3">
                    {testmic == 0 && (
                      <Col md="4">
                        <div className="bg-white text-examheadtext py-2 px-5 d-flex flex-column justify-content-between align-items-center gap-3 h-100">
                          <p>
                            Microphone{" "}
                            {!record ? (
                              <Spinner
                                animation="border"
                                role="status"
                                size="sm"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </Spinner>
                            ) : (
                              <Button
                                className="btn-icon rounded-circle"
                                size="sm"
                                variant="hisuccess"
                              >
                                <Icon name="check"></Icon>
                              </Button>
                            )}
                          </p>
                          {/* <button onClick={()=>{setRecord(false)}}> uuuu</button> */}
                          <ReactMic
                            record={record}
                            visualSetting="frequencyBars"
                            // className="sound-wave"

                            onStop={onStop}
                            onData={onData}
                            strokeColor="#32ba46"
                            noiseSuppression={true} // defaults -> false
                            channelCount={1} // defaults -> 2 (stereo).  Specify 1 for mono.
                            bitRate={256000} // defaults -> 128000 (128kbps).  React-Mic-Gold only.
                            sampleRate={44100} // defaults -> 44100 (44.1 kHz).  It accepts values only in range: 22050 to 96000 (available in React-Mic-Gold)
                            timeSlice={1000} // defaults -> 4000 milliseconds.  The interval at which captured audio is returned to onData callback (available in React-Mic-Gold).
                            // backgroundColor="#FF4081"
                            audioRef={audioRef}
                          />
                          {/* <ProgressBar
                          now={progress*100}
                          variant="hisuccess"
                          style={{ height: "30px", width: "100%" }}
                        /> */}
                          <Button
                            variant="primary"
                            onClick={() => setRecord(true)}
                          >
                            Start Check Microphone
                          </Button>
                          {/* <p className="text-center">Speak aloud 1,2,3,4</p>
                        <Form.Select removeItemButton>
                          <option value="">Select</option>
                          <option value="1">Default - MacBook Air M</option>
                          <option value="2">Windows 10</option>
                        </Form.Select> */}
                        </div>
                      </Col>
                    )}
                    {testmic == 1 && (
                      <Col md="4">
                        <div className="bg-white text-examheadtext py-2 px-5 d-flex flex-column justify-content-between align-items-center gap-3 h-100">
                          <p>
                            Microphone{" "}
                            {!record ? (
                              <Spinner
                                animation="border"
                                role="status"
                                size="sm"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </Spinner>
                            ) : (
                              <Button
                                className="btn-icon rounded-circle"
                                size="sm"
                                variant="hisuccess"
                              >
                                <Icon name="check"></Icon>
                              </Button>
                            )}
                          </p>
                          <ReactMic
                            record={record}
                            visualSetting="frequencyBars"
                            // className="sound-wave"

                            onStop={onStop}
                            onData={onData}
                            strokeColor="#32ba46"
                            noiseSuppression={true} // defaults -> false
                            channelCount={1} // defaults -> 2 (stereo).  Specify 1 for mono.
                            bitRate={256000} // defaults -> 128000 (128kbps).  React-Mic-Gold only.
                            sampleRate={44100} // defaults -> 44100 (44.1 kHz).  It accepts values only in range: 22050 to 96000 (available in React-Mic-Gold)
                            timeSlice={1000} // defaults -> 4000 milliseconds.  The interval at which captured audio is returned to onData callback (available in React-Mic-Gold).
                            // backgroundColor="#FF4081"
                            audioRef={audioRef}
                          />
                          {/* <ProgressBar
                          now={progress*100}
                          variant="hisuccess"
                          style={{ height: "30px", width: "100%" }}
                        /> */}
                          <Button
                            variant="primary"
                            onClick={() => checkMicrophoneAvailability()}
                          >
                            Test microphone
                          </Button>
                          {/* <p className="text-center">Speak aloud 1,2,3,4</p>
                        <Form.Select removeItemButton>
                          <option value="">Select</option>
                          <option value="1">Default - MacBook Air M</option>
                          <option value="2">Windows 10</option>
                        </Form.Select> */}
                        </div>
                      </Col>
                    )}
                    {testmic == 2 && (
                      <Col md="4">
                        <div className="bg-white text-examheadtext py-2 px-5 d-flex flex-column justify-content-between align-items-center gap-3 h-100">
                          <p>
                            Microphone{" "}
                            <Button
                              className="btn-icon rounded-circle"
                              size="sm"
                              variant="hisuccess"
                            >
                              <Icon name="check"></Icon>
                            </Button>
                          </p>
                          <ProgressBar
                            now={40}
                            variant="hisuccess"
                            style={{ height: "30px", width: "100%" }}
                          />
                          <p className="text-center">Speak aloud 1,2,3,4</p>
                          <Form.Select removeItemButton>
                            <option value="">Select</option>
                            <option value="1">Default - MacBook Air M</option>
                            <option value="2">Windows 10</option>
                          </Form.Select>
                        </div>
                      </Col>
                    )}

                    {testspeaker == 0 && (
                      <Col md="4">
                        <div className="bg-white text-examheadtext p-2 d-flex flex-column justify-content-between align-items-center gap-3 h-100">
                          <p>Speakers</p>
                          {"   "}
                          <img src="/images/speaker.svg"></img>
                          <p>Start the audio sample.</p>
                          <Button
                            variant="primary"
                            onClick={() => {
                              setforMicSpeakerUI({
                                ...forMicSpeakerUI,
                                testspeaker: testspeaker + 1,
                              });
                            }}
                          >
                            Start speaker check
                          </Button>
                        </div>
                      </Col>
                    )}

                    {testspeaker == 1 && (
                      <Col md="4">
                        <div className="bg-white text-examheadtext p-2 d-flex flex-column justify-content-between align-items-center gap-3 h-100">
                          <p>
                            Speakers{" "}
                            <Spinner animation="border" role="status" size="sm">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>

                          </p>
                          <img src="/images/speaker.svg"></img>
                          <audio
                            className="d-none"
                            controls
                            autoPlay
                            src="/images/audio.wav"
                          ></audio>
                          <p>Can you hear the music playing?</p>
                          <div className="d-flex">
                            <Form.Check
                              inline
                              label="Yes"
                              onClick={() => {
                                setforMicSpeakerUI({
                                  ...forMicSpeakerUI,
                                  testspeaker: testspeaker + 1,
                                });
                              }}
                              type="radio"
                              name="radiolist"
                            ></Form.Check>
                            <Form.Check
                              inline
                              label="No"
                              onClick={() => {
                                setforMicSpeakerUI({
                                  ...forMicSpeakerUI,
                                  testspeaker: testspeaker + 1,
                                }); setspeakerWorking(false)
                              }}
                              checked={!speakerWorking ? true : false}
                              type="radio"
                              name="radiolist"
                            ></Form.Check>
                          </div>
                        </div>
                      </Col>
                    )}
                    {testspeaker == 2 && (
                      <Col md="4">
                        <div className="bg-white text-examheadtext p-2 d-flex flex-column justify-content-between align-items-center gap-3 h-100">
                          <p>
                            Speakers{" "}
                            {speakerWorking ? (
                              <Button
                                className="btn-icon rounded-circle mx-2"
                                size="sm"
                                variant="hisuccess"
                              >
                                <Icon
                                  name="check"
                                  className="btn-icon rounded-circle mx-2"
                                ></Icon>
                              </Button>
                            ) : (
                              <Button
                                className="btn-icon rounded-circle mx-2"
                                size="sm"
                                variant="danger"
                              >
                                <Icon
                                  name="cross"
                                  className="btn-icon rounded-circle mx-2"
                                ></Icon>
                              </Button>
                            )}
                          </p>
                          <img src="/images/speaker.svg"></img>
                          <p>Can you hear the music playing?</p>
                          <div className="d-flex">
                            <Form.Check
                              inline
                              label="Yes"
                              onChange={() => setspeakerWorking(true)}
                              checked={speakerWorking ? speakerWorking : false}
                              type="radio"
                              name="radiolist"
                            ></Form.Check>
                            <Form.Check
                              inline
                              label="No"
                              onClick={() => setspeakerWorking(false)}
                              checked={!speakerWorking ? true : false}
                              type="radio"
                              name="radiolist"
                            ></Form.Check>
                          </div>
                        </div>
                      </Col>
                    )}
                    {internetspeed == 0 && (
                      <Col md="4">
                        <div className="bg-white text-examheadtext p-2 d-flex flex-column justify-content-between align-items-center gap-3 h-100">
                          <p>Internet Speed</p>
                          <SpeedTestForMockTest internetSpeedTest={internetSpeedTest} fileUrl={"https://hilingo.s3.ap-south-1.amazonaws.com/qst/2024-06-19/speaking/retell_lectures/9496/Video/654+Sustainable+Farming+%28online-video-cutter.com%29+%281%29.mp4"} />
                          {/* <img src="/images/internet-speed.svg"></img> */}
                          <Button
                            variant="primary"
                            onClick={() => {
                              // setInternetSpeedTest(true)
                              setInstCount(33)

                            }}
                          >
                            {" "}
                            Start speed Test{" "}
                          </Button>
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              </Col>
            </Row>
          )}
          {countStep == 3 && (
            <InstructionTable
              examsTime={examsTime}
              countStep={countStep}
              examtype={examtype}
              all={all}
              section_wise={section_wise}
              extractTime={extractTime}
              id={id}
            />
          )}

          {countStep == 4 && (
            <Row className="my-3">
              <Col md="12" className="text-examheadtext">
                <h4 className="text-examheadtext">How to complete the text</h4>
                <ul className="mx-3 exam-list">
                  <li>The test measures Reading, Writing, Speaking and Listening skills in English.</li>
                  <li>Different varieties of English are used in the test, for example, British, Amrican, and Australian English. You can answer in the standard English variety of your choice.</li>
                  <li>The test is divided into 3 parts. Each part may contain a number of sections. The sections are individually timed. The timer will be shown in the top right corner of your screen. The number of items in the section will also be displayed.
                    <span className="d-inline-flex flex-column align-items-end bg-examtop px-3 text-examheadtext my-2">
                      <span className="d-flex text-examheadtext align-items-center">
                        <span>Time Remaining</span>
                        <Icon name="clock"></Icon>
                        <span>12:49 / 30:00</span>
                      </span>
                      <span>
                        <Icon name="files"></Icon>
                        <span> 1 of 22 </span>
                      </span>
                    </span>
                  </li>
                  <li>The timer can be hidden by clicking on the clock icon. Click on the icon again to bring the timer back.
                    <div className="d-flex justify-content-start gap-5">
                      <span className="d-inline-flex flex-column align-items-end bg-examtop px-3 text-examheadtext my-2">
                        <span className="d-flex text-examheadtext align-items-center">
                          <span>Time Remaining</span>
                          <Icon name="clock"></Icon>
                          <span>12:49 / 30:00</span>
                        </span>
                        <span>
                          <Icon name="files"></Icon>
                          <span> 1 of 22 </span>
                        </span>
                      </span>
                      <span className="d-inline-flex flex-column align-items-end bg-examtop px-3 text-examheadtext my-2">
                        <span className="d-flex text-examheadtext align-items-center">
                          <span className="invisible">Time Remaining</span>
                          <span className="invisible">12:49 / 30:00</span>
                          <Icon name="clock"></Icon>
                        </span>
                        <span>
                          <span className="invisible"> 1 of 22 </span>
                          <Icon name="files"></Icon>
                        </span>
                      </span>
                    </div>
                  </li>
                  <li>At the beginning of each part, you will receive instructions. These will provide detail on what to expect in that part of the test.</li>
                  <li>Use the Next (<u>N</u>) button at the bottom of each screen, you confirm your answer and move to the next question.</li>
                  <li>If you click on Next (<u>N</u>) you will <b>not</b> be able to return to the previous question. You will <b>not</b> be able to go back to any question at the end of the test.</li>
                  <li>There is no break in the test.</li>
                </ul>
                <p className="bg-examblue text-white px-3 mt-5">
                  Click on the Next (N) button to continue
                </p>
              </Col>
            </Row>
          )}
          {viewInstruction == "speaking" && (
            <InstructionSpeaking instCount={instCount} examsTime={examsTime} />
          )}
{console.log("viewInstruction",viewInstruction)}
          {viewInstruction == "reading" && (
            <InstructionReading instCount={instCount} examsTime={examsTime} />
          )}

          {viewInstruction == "listening" && (
            <ListeningInstruction instCount={instCount} examsTime={examsTime} />
          )}

          {(viewInstruction == null && ques?.module_name == "read_alouds") && (
            <>
              <ReadAloud
                examsTime={examsTime}
                key={count} data={ques}
                popUpType={popUpType}
                stopRec={() =>
                  setManageRecordings({
                    ...manageRecordings,
                    start: false,
                    stop: true,
                  })
                }
                startRec={() =>
                  setManageRecordings({
                    ...manageRecordings,
                    start: true,
                    stop: false,
                  })
                }
              />
            </>
          )}

          {viewInstruction == null &&
            ques?.module_name == "answer_questions" && (
              <>
                <AnswerShortQuestions
                  key={count} data={ques}
                  popUpType={popUpType}
                  stopRec={() =>
                    setManageRecordings({
                      ...manageRecordings,
                      start: false,
                      stop: true,
                    })
                  }
                  startRec={() =>
                    setManageRecordings({
                      ...manageRecordings,
                      start: true,
                      stop: false,
                    })
                  }
                />
              </>
            )}
          
          {viewInstruction == null &&
            ques?.module_name == "repeat_sentences" && (
              <>
              
                <RepeatSenetence
                  popUpType={popUpType}
                  key={count} data={ques}
                  stopRec={() =>
                    setManageRecordings({
                      ...manageRecordings,
                      start: false,
                      stop: true,
                    })
                  }
                  startRec={() =>
                    setManageRecordings({
                      ...manageRecordings,
                      start: true,
                      stop: false,
                    })
                  }
                />
              </>
            )}

          {viewInstruction == null &&
            ques?.module_name == "describe_images" && (
              <DescribeImage
                popUpType={popUpType}
                key={count} data={ques}
                setModal={setModal}
                answerJson={answerJson}
                stopRec={() =>
                  setManageRecordings({
                    ...manageRecordings,
                    start: false,
                    stop: true,
                  })
                }
                startRec={() =>
                  setManageRecordings({
                    ...manageRecordings,
                    start: true,
                    stop: false,
                  })
                }
              />
            )}
          {viewInstruction == null &&
            ques?.module_name == "retell_lectures" && (
              <>
             
                <ReTellLectureImage
                  key={count} data={ques}
                  popUpType={popUpType}
                  stopRec={() =>
                    setManageRecordings({
                      ...manageRecordings,
                      start: false,
                      stop: true,
                    })
                  }
                  startRec={() => {
                    setManageRecordings({
                      ...manageRecordings,
                      start: true,
                      stop: false,
                    })
                  }
                  }
                />
              </>
            )}
          {(viewInstruction == null || viewInstruction == "writing") && ques?.module_name == "swts" && (
            <SummarizeWrittenText key={count} data={ques} />
            
          )}

          {(viewInstruction == null || viewInstruction == "writing") && ques?.module_name == "essays" && (
            <WriteEssay key={count} data={ques} />
          )}

          {viewInstruction == null && ques?.module_name == "fib_wr" && (
            <ReadWriteFillinBlanks key={count} data={ques} />
          )}

          {viewInstruction == null && ques?.module_name == "r_mcm" && (
            <ReadingMultichoice key={count} data={ques} />
          )}

          {viewInstruction == null && ques?.module_name == "ro" && (
            <ReadingReOrder key={count} data={ques} />
          )}
          {viewInstruction == null && ques?.module_name == "fib_rd" && (
            <ReadingFillInBlanks key={count} data={ques} />
          )}
          {viewInstruction == null && ques?.module_name == "r_mcs" && (
            <ReadingMultichoiceSingle key={count} data={ques} />
          )}

          {/* ========================================================Listening section ===================================================================== */}

          {viewInstruction == null && ques?.module_name == "ssts" && (
            <>
              <ListeningSummarizeSpokenText key={count} data={ques} />
            </>
          )}
          {viewInstruction == null && ques?.module_name == "l_smw" && (
            <>
              <SelectMissingWord key={count} data={ques} />
            </>
          )}

          {viewInstruction == null && ques?.module_name == "l_mcm" && (
            <>
              <ListeningMultiChoiceMulti key={count} data={ques} />
            </>
          )}

          {viewInstruction == null && ques?.module_name == "l_mcs" && (
            <>
              <ListeningMultiChoiceSingle
                instructions={ques.instructions}
                key={count} data={ques}
              />
            </>
          )}

          {viewInstruction == null && ques?.module_name == "l_fib" && (
            <>
              <ListeningFillInBlanks key={count} data={ques} />
            </>
          )}

          {viewInstruction == null && ques?.module_name == "l_hcs" && (
            <>
              <ListeningHighlightCorrectSummary key={count} data={ques} />
            </>
          )}

          {viewInstruction == null && ques?.module_name == "hiws" && (
            <>
              <ListeningHighlightIncorrectWord key={count} data={ques} />
            </>
          )}

          {viewInstruction == null && ques?.module_name == "wfds" && (
            <>
              <ListeningDictation key={count} data={ques} />
            </>
          )}

          {countStep == "endExam" && viewInstruction == "endExam" && (
            <>
              <p className="bg-examseagreen text-white px-3 py-1 mb-5">
                <img
                  src="/images/favicon.png"
                  alt="logo"
                  className="intro-logo"
                />{" "}
                Lingo | PTE Academic
              </p>
              <Row className="my-3">
                <Col md="12" className="text-examheadtext">
                  <h4 className="text-examheadtext">End of the Test</h4>
                  <ul className="mx-3" style={{ listStyleType: "disc" }}>
                    <li>Thank you for completing PTE Academic Mock Test With Us.</li>
                    <li style={{lineHeight:"1.5"}}>
                      Your mock test score will be ready typically within 10 minutes. In the meantime, you can check the status in the Result Management area on your Student Dashboard. You will receive an email notification when your score is ready, typically within three days. In the meantime, you can check the status on your My PTE Dashboard.
                    </li>
                    <li>
                      Please click on the End Exam (E) now to leave the test.
                    </li>
                  </ul>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-between bg-exambottom px-5 position-fixed bottom-0 w-100 align-items-center">
        <div className="w-25">

          {(viewInstruction == null || viewInstruction == "writing") && question.length > 0 && (
            <Button
              variant="bg-transparent"
              className="text-examheadtext"
              onClick={saveExitStep}
            >
              <img src="/images/exam-end.svg"></img>

              <span className="ms-1">Save and Exit</span>
            </Button>
          )}
        </div>
        <div className="w-50">
          <p className="d-flex justify-content-center w-100 m-0 align-items-center text-examheadtext">
            Powered By Hi-Lingo Technology
          </p>
        </div>
        <div className="w-25 d-flex justify-content-end">
          {countStep != 1 && countStep < 5 && !param && examtype != "questionType" && (
            <>
              <Button
                style={{ borderLeft: "2px solid #fff", borderRadius: "0px" }}
                variant="bg-transparent"
                className="text-examheadtext"
                onClick={() => { prevStep(); setRecord(false); stopRecording() }}
              >
                <img src="/images/exam-left-arrow-foot.svg"></img>
                <span className="ms-1">Previous</span>
              </Button>
            </>
          )}
          {countStep != 32 && (
            <>
              {countStep == "endExam" && viewInstruction == "endExam" ? (
                <Button
                  style={{ borderLeft: "2px solid #fff", borderRadius: "0px" }}
                  variant="bg-transparent"
                  className="text-examheadtext align-items-center"
                  onClick={() => {
                    EndExamModalShow();
                  }}
                >
                  <span className="me-1">End</span>
                  <img
                    src="/images/exam-right-arrow-foot-1.svg"
                    className="width-12"
                  ></img>
                </Button>
              ) : (
                <Button
                  style={{ borderLeft: "2px solid #fff", borderRadius: "0px" }}
                  variant="bg-transparent"
                  className="text-examheadtext align-items-center"
                  onClick={() => {
                    nextStep();
                  }}
                >
                  <span className="me-1">Next</span>
                  <img
                    src="/images/exam-right-arrow-foot-1.svg"
                    className="width-12"
                  ></img>
                </Button>
              )}
              {/* <Button
                variant="bg-transparent"
                className="text-examheadtext"
                onClick={nextStep}
              >
                {" "}
                Next
              </Button> */}
            </>
          )}
          {countStep == 32 && (
            <>
              <Button
                style={{ borderLeft: "2px solid #fff", borderRadius: "0px" }}
                variant="bg-transparent"
                className="text-examheadtext align-items-center"
                onClick={EndExamModalShow}
              >
                <span className="me-1">Next</span>
                <img
                  src="/images/exam-right-arrow-foot-1.svg"
                  className="width-12"
                ></img>
              </Button>
              {/* <Button
                variant="bg-transparent"
                className="text-examheadtext"
                onClick={EndExamModalShow}
              >
                {" "}
                Next
              </Button> */}
            </>
          )}
        </div>
      </div>

      <Modal size="lg" show={!record}>
        <img src="/images/microphone-permission.png"></img>
      </Modal>

      <Modal
        show={fullscreen}
        fullscreen={true}
        onHide={() => setFullscreen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Speed Test</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="pos">
            <iframe
              src="https://openspeedtest.com/Get-widget.php"
              width="735"
              scrolling="no"
              height="490"
              frameborder="0"
            ></iframe>
            <br />
            <div style={{ width: "725px" }}></div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="secondary"
            onClick={() => {
              setFullscreen(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        className="exam-modal text-black"
        show={BeginExamModal}
        onHide={BeginExamModalClose}
        scroll={false}
        backdrop={false}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-black">Ready to Begin Exam</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex gap-3">
            <img src="/images/exam-que-icon.svg"></img>
            <p>
              {" "}
              If you are ready to begin the exam, select the Yes button.
              Otherwise, select to No button to return to the previous screen
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            size="sm"
            variant="outline-light"
            className="border-2 rounded-0 text-black"
            onClick={() => { BeginExamModalClose(); get_paper(); setCountStep(examtype != "questionType" ? countStep + 1 : null) }}
          >
            Yes
          </Button>
          <Button
            size="sm"
            variant="outline-light"
            className="border-2 rounded-0 text-black"
            onClick={() => { navigate(-1) }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

      {(popUpType == "prepare" || popUpType == "prepare_question") && (
        <Modal
          className="exam-modal text-black"
          show={NextQuestionModal}
          onHide={NextQuestionModalClose}
          scroll={false}
          backdrop={false}
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-black">Cannot Skip</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex gap-3">
              <img src="/images/exam-que-icon.svg"></img>
              <p> you need to finish this question before goint to next</p>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button
              size="sm"
              variant="outline-light"
              className="border-2 rounded-0 text-black"
              onClick={NextQuestionModalClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {popUpType == "recordingStopped" && (
        <Modal
          className="exam-modal text-black"
          show={true}
          onHide={() => submitAnswer()}
          scroll={false}
          backdrop={false}
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-black">Recording Stopped</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex gap-3">
              <img src="/images/exam-que-icon.svg"></img>
              <p> Please click Next to  go to next question</p>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button
              size="sm"
              variant="outline-light"
              className="border-2 rounded-0 text-black"
              onClick={() => (viewInstruction == "speaking" && instCount == 3) ? (setPopUpType("default"), nextStep()) : submitAnswer()}
            >
              Next
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {popUpType == "timeUp" && (
        <Modal
          className="exam-modal text-black"
          show={true}
          onHide={NextQuestionModalClose}
          scroll={false}
          backdrop={false}
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-black">Time's Up</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex gap-3">
              <img src="/images/exam-que-icon.svg"></img>
              <p> Please click Next to go next question</p>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button
              size="sm"
              variant="outline-light"
              className="border-2 rounded-0 text-black"
              onClick={() => (viewInstruction == "reading" || viewInstruction == "listening" || viewInstruction == "speaking") ? nextStep(true) : submitAnswer()}
            >
              Next
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {!isOnline && (
        <Modal
          className="exam-modal text-black"
          show={true}
          onHide={NextQuestionModalClose}
          scroll={false}
          backdrop={false}
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-black">Internet Connection not working </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex gap-3">
              <img src="/images/exam-que-icon.svg"></img>
              <p> Please check your internet connection</p>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button
              size="sm"
              variant="outline-light"
              className="border-2 rounded-0 text-black"
              onClick={() => { navigate("/student/mock/list"); setPopUpType("prepare") }}
            >
              Retry
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {popUpType == "default" && (
        <Modal
          className="exam-modal text-black"
          show={NextQuestionModal}
          onHide={NextQuestionModalClose}
          scroll={false}
          backdrop={false}
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-black">Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex gap-3">
              <img src="/images/exam-que-icon.svg"></img>
              <p>
                {" "}
                Are you sure if you want to submit this answer and go to the
                next question?
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button
              size="sm"
              variant="outline-light"
              className="border-2 rounded-0 text-black"
              onClick={() => submitAnswer()}
            >
              Yes
            </Button>
            <Button
              size="sm"
              variant="outline-light"
              className="border-2 rounded-0 text-black"
              onClick={NextQuestionModalClose}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Modal
        className="exam-modal text-black"
        show={enableSaveAndExistAlert}
        onHide={() => setEnableSaveAndExistAlert(false)}
        scroll={false}
        backdrop={false}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-black">Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex gap-3">
            <img src="/images/exam-que-icon.svg"></img>
            <p> Are you sure to save and exit the test ?</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            size="sm"
            variant="outline-light"
            className="border-2 rounded-0 text-black"
            onClick={() => { saveCurrentTime(); navigate("/student/mock/list"); setPopUpType("prepare") }}
          >
            Yes
          </Button>
          <Button
            size="sm"
            variant="outline-light"
            className="border-2 rounded-0 text-black"
            onClick={() => setEnableSaveAndExistAlert(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="exam-modal text-black"
        show={EndExamModal}
        onHide={EndExamModalClose}
        scroll={false}
        backdrop={false}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-black">End Exam</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex gap-3 align-items-start">
            <img src="/images/exam-end-warning.svg"></img>
            <div>
              <p> You have choose to end this exam.</p>
              <p>Are you sure you want to end this exam?</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            size="sm"
            variant="outline-light"
            className="border-2 rounded-0 text-black"
            onClick={EndExamModalClose}
          >
            Yes
          </Button>
          <Button
            size="sm"
            variant="outline-light"
            className="border-2 rounded-0 text-black"
            onClick={EndExamModalClose}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default PTEScoredTestIntro;